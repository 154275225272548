import { Text, TextProps } from "@chakra-ui/react";

export interface SmallText1Props extends TextProps {}

export default function SmallText1({ children, ...props }: SmallText1Props) {
  return (
    <Text as="p" fontSize="12px" fontWeight={600} color="black" {...props}>
      {children}
    </Text>
  );
}
