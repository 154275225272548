import { useState, useEffect } from "react";
import { Box, Container, Image, Text } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "./v2/Button";
import HomeIcon from "./icons/Home";
import CartComponent from "./icons/Cart";

import logoFullWhite from "@/images/logo-full-white.png";
import { RootState } from "@/store";
import CoursesIcon from "./icons/Teach";
import UserIcon from "./icons/User";
import NursingIcon from "./icons/Nursing";
import { actions } from "@/store/auth";
import SearchIcon from "./icons/Search";

interface MenuItem {
  name: string;
  icon: JSX.Element;
  to: string;
}

const data: MenuItem[] = [
  {
    name: "Inicio",
    icon: <HomeIcon />,
    to: "/",
  },
  {
    name: "Nosotros",
    icon: <NursingIcon />,
    to: "/nosotros",
  },
  {
    name: "Catálogo de Cursos",
    icon: <CoursesIcon stroke="#83B2FC" width="24px" height="24px" />,
    to: "/cursos",
  },
  {
    name: "Carrito de compras",
    icon: <CartComponent stroke="#83B2FC" />,
    to: "/pagar",
  },
];

const dataAdmin: MenuItem[] = [
  {
    name: "Inicio",
    icon: <HomeIcon />,
    to: "/",
  },
  {
    name: "Administrar Cursos",
    icon: <CoursesIcon stroke="#83B2FC" width="24px" height="24px" />,
    to: "/backoffice",
  },
];

export default function Header() {
  const [show, setShow] = useState(false);
  const showDot = useSelector((state: RootState) => {
    return Object.keys(state.cart).length > 0;
  });
  const jwt = useSelector((state: RootState) => state.auth.jwt);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // bind esc key to close menu
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShow(false);
      }
    };
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [show]);

  const renderMenuItem = (item: MenuItem, index: number) => (
    <Box
      width="100%"
      display="flex"
      as={Link}
      to={item.to}
      key={index}
      mb="16px"
    >
      {item.icon}{" "}
      <Text ml="16px" color="white">
        {item.name}
      </Text>
    </Box>
  );

  return (
    <Box height="64px">
      <Box
        bg="brand.100"
        p="8px"
        display="flex"
        borderRadius="0 0 64px 16px"
        justifyContent="space-between"
        alignItems="center"
        zIndex={10}
        position="absolute"
        width="100%"
      >
        <Button variant="tertiary" onClick={() => setShow(!show)}>
          {show ? <CloseIcon color="white" /> : <HamburgerIcon color="white" />}
        </Button>
        <Link to="/">
          <Image src={logoFullWhite} width="168" height="25" alt="logo" />
        </Link>
        <Box mr="16px">
          {jwt ? (
            <Link to="/backoffice">
              <SearchIcon color="white" />
            </Link>
          ) : (
            <Link to="/pagar">
              <CartComponent showDot={showDot} />
            </Link>
          )}
        </Box>
      </Box>
      {show && (
        <Box
          position="absolute"
          onClick={() => setShow(false)}
          width="100%"
          height="100%"
        >
          <Box
            bgColor="brand.100"
            width="100%"
            position="absolute"
            top="0"
            pt="72px"
            pb="16px"
            zIndex={1}
            borderRadius="0 0 48px 16px"
          >
            <Container>
              {jwt ? dataAdmin.map(renderMenuItem) : data.map(renderMenuItem)}
              {jwt ? (
                <Button
                  variant="secondary"
                  className="flex"
                  onClick={() => dispatch(actions.clearData())}
                >
                  Cerrar sesión <CloseIcon style={{ marginLeft: 8 }} />
                </Button>
              ) : (
                <Button
                  className="flex"
                  onClick={() => navigate("/login")}
                  variant="secondary"
                >
                  Iniciar sesión <UserIcon style={{ marginLeft: 8 }} />
                </Button>
              )}
            </Container>
          </Box>
        </Box>
      )}
    </Box>
  );
}
