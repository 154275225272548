import { lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/es";
import BackofficeLayout from "./layouts/BackofficeLayout";

import MainLayout from "./layouts/MainLayout";

const Home = lazy(() => import("./pages/Home"));
const Courses = lazy(() => import("./pages/Courses"));
const Course = lazy(() => import("./pages/Course"));
const SignupForm = lazy(() => import("./pages/SignupForm"));
const PaymentLayout = lazy(() => import("./layouts/PaymentLayout"));
const Payment = lazy(() => import("./pages/Payment"));
const PaymentData = lazy(() => import("./pages/PaymentData"));
const PaymentCard = lazy(() => import("./pages/PaymentCard"));
const PaymentConfirmation = lazy(() => import("./pages/PaymentConfirmation"));
const Login = lazy(() => import("./pages/backoffice/Login"));
const HomeBackoffice = lazy(() => import("./pages/backoffice/Home"));
const CourseBackoffice = lazy(() => import("./pages/backoffice/Course"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Materials = lazy(() => import("./pages/Materials"));

dayjs.locale("es");
dayjs.extend(localizedFormat);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/nosotros" element={<AboutUs />} />
      <Route path="/cursos" element={<Courses />} />
      <Route path="/cursos/:id" element={<Course />} />
      <Route path="/login" element={<Login />} />
      <Route path="/registro" element={<SignupForm />} />
      <Route path="/materiales" element={<Materials />} />
      <Route path="/backoffice" element={<BackofficeLayout />}>
        <Route path="/backoffice" element={<HomeBackoffice />} />
        <Route
          path="/backoffice/cursos/:courseId"
          element={<CourseBackoffice />}
        />
      </Route>
      <Route path="/pagar" element={<PaymentLayout />}>
        <Route path="/pagar" element={<Payment />} />
        <Route path="/pagar/datos" element={<PaymentData />} />
        <Route path="/pagar/tarjeta" element={<PaymentCard />} />
        <Route path="/pagar/confirmacion" element={<PaymentConfirmation />} />
      </Route>
      <Route path="*" element={<div>404</div>} />
    </Route>
  )
);

export default function App() {
  return <RouterProvider router={router} />;
}
