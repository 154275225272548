import { forwardRef } from "react";
import clsx from "clsx";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "tertiary";
}

export const variants = {
  primary:
    "bg-royal-blue-dark disabled:bg-gray-1 disabled:text-gray-2 text-royal-blue-dark-2",
  secondary:
    "bg-baby-blue-eyes-2 text-royal-blue-dark-1 disabled:bg-gray-2 disabled:text-gray-1",
  tertiary: "bg-transparent text-royal-blue-dark-1 disabled:text-gray-1",
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, variant = "primary", className, ...rest } = props;
  return (
    <button
      ref={ref}
      className={clsx(
        "px-6 py-3 rounded-2xl text-base font-bold",
        variant === "tertiary" ? "" : "hover:shadow-md disabled:shadow-none",
        variants[variant],
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
});

export default Button;
