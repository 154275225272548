import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#194B9B"
    {...props}
  >
    <path
      d="M2.5 15.686h20M4.81 19.45h15.38M13.573 8.767l4.594-3.459M1.5 1h22v22h-22V1Zm5.481 14.686c-.015-1.43.103-5.093.7-5.789.598-.695 2.38-.405 3.197-.174l2.216-1.565.957 1.218-2.31 2.986v3.324h-4.76Zm4.367-9.294a2.106 2.106 0 1 1-4.21 0 2.106 2.106 0 0 1 4.21 0Z"
      stroke="auto"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
