import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  jwt?: string;
}

const initialState: UserState = {
  jwt: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setJwt: (state, action: PayloadAction<string>) => {
      const jwt = action.payload;
      localStorage.setItem("jwt", jwt);
      return { ...state, jwt };
    },
    clearData: (state) => {
      localStorage.removeItem("jwt");
      return initialState;
    },
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
