import { Box, CircularProgress } from "@chakra-ui/react";

const FullscreenLoader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress isIndeterminate color="brand.100" />
    </Box>
  );
};

export default FullscreenLoader;
