import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    fontWeight: "600",
    color: "gray.1",
    fontSize: "12px",
    _selected: {
      color: "brand.100",
    },
  },
});

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle });
