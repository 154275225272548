import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  [key: string]: any;
}

const initialState: UserState = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    remindData: (state, action) => {
      const user = action.payload;
      return { ...state, ...user };
    },
    clearData: () => {
      return initialState;
    },
  },
});

export const actions = userSlice.actions;

export default userSlice.reducer;
