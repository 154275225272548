import { useToast } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "@/store";

export default function BackofficeLayout() {
  const { jwt } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const toast = useToast();
  if (!jwt) {
    navigate("/login", { replace: true });
    toast({
      title: "Error",
      description: "No tienes permisos para acceder a esta página",
      status: "error",
    });
  }
  return <Outlet />;
}
