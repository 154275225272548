import Body2 from "@/components/text/Body2";
import { Box, Text, Container, Image, useBoolean } from "@chakra-ui/react";

import facebook from "@/images/facebook.svg";
import instagram from "@/images/instagram.svg";
import linkedIn from "@/images/linkedIn.svg";
import tikTok from "@/images/tikTok.svg";
import whatsApp from "@/images/whatsApp.svg";
import SmallText1 from "@/components/text/SmallText1";
import { Link } from "react-router-dom";
import PrivacyModal from "@/components/modals/Privacy";

export default function Footer() {
  const [modal, modalActions] = useBoolean();

  return (
    <Box bgColor="brand.100" p="24px 16px" borderRadius="32px 72px 0px 0px">
      <Container maxW="container.md">
        <Text
          textAlign="left"
          color="brand.500"
          textDecor="underline"
          fontSize="22px"
          fontWeight="900"
          mb="16px"
        >
          Sobre nosotros
        </Text>
        <Body2 color="white" mb="16px">
          RCP-E para Enfermería®
        </Body2>
        <Body2 color="white" mb="16px">
          Centro de Capacitación en Apoyo Vital A.C.
        </Body2>
        <Text
          textAlign="left"
          color="brand.500"
          textDecor="underline"
          fontSize="22px"
          fontWeight="900"
          mb="16px"
        >
          Servicios
        </Text>
        <Body2 color="white" mb="16px">
          Cotiza cursos privados grupos
        </Body2>
        <Body2 color="white" mb="16px">
          ¿Eres ponente? ¡Da clases!
        </Body2>
        <Body2 as={Link} to="/registro" sx={{ mb: "16px" }} color="white">
          Inscripciones offline
        </Body2>
        <Body2 color="white" mb="16px">
          Recibe la oferta académica en tu celular
        </Body2>
        <Text
          textAlign="left"
          color="brand.500"
          textDecor="underline"
          fontSize="22px"
          fontWeight="900"
          mb="16px"
        >
          Contacto
        </Text>
        <Box mb="24px">
          <Body2 as="a" href="tel:+(52) 55 7866 5262" color="white">
            +(52) 55 7866 5262
          </Body2>
        </Box>
        <Box mb="16px">
          <Body2 as="a" href="mailto:rcpparaenfermeria@gmail.com" color="white">
            rcpparaenfermeria@gmail.com
          </Body2>
        </Box>
        <Box display="flex" justifyContent="center" mb="16px">
          <Box display="inline-flex" flexWrap="wrap" gap="16px">
            <a href="https://bit.ly/3wRoUc2" target="_blank" rel="noreferrer">
              <Image src={whatsApp} alt="WhatsApp" />
            </a>
            <a
              href="https://www.facebook.com/RCPparaenfermeria"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={facebook} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/rcpparaenfermeria/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={instagram} alt="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/rcp-e-para-enfermeria"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={linkedIn} alt="LinkedIn" />
            </a>
            <a
              href="https://www.tiktok.com/@rcpparaenfermeria"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={tikTok} alt="TikTok" />
            </a>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mb="16px">
          <SmallText1 color="white" cursor="pointer" onClick={modalActions.on}>
            Aviso de Privacidad
          </SmallText1>
          <SmallText1 color="white">Política de cookies</SmallText1>
        </Box>
        <SmallText1 color="white" textAlign="center">
          Copyright © RCP-E para Enfermería® 2022
          <br />
          Todos los Derechos Reservados
        </SmallText1>
      </Container>
      <PrivacyModal isOpen={modal} onClose={modalActions.off} />
    </Box>
  );
}
