import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "@/store";
import { initializeIcons } from "@uifabric/icons";

import theme from "./styles/theme";
import App from "./App";
import "@/services/axios.interceptors";

import "./index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {},
  },
});

const dev = import.meta.env.DEV;

!dev &&
  TagManager.initialize({
    gtmId: "GTM-K72ZRF7",
  });

!dev &&
  Sentry.init({
    dsn: "https://26e82857a5ca4675848beb703fa872a3@o1361672.ingest.sentry.io/4504437079539712",
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });

initializeIcons();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          {dev && <ReactQueryDevtools />}
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </ChakraProvider>
);
