import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBoolean,
} from "@chakra-ui/react";
import Body2 from "../text/Body2";
import H3 from "../text/H3";

export interface PrivacyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PrivacyModal({ isOpen, onClose }: PrivacyModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding="12px 16px">
        <ModalHeader>
          <H3 textAlign="center" mb="32px">
            Aviso de Privacidad
          </H3>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Body2>
            De conformidad con lo establecido en la Ley Federal de Protección de
            Datos Personales en Posesión de los Particulares, RCP-E para
            Enfermería® pone a su disposición el siguiente aviso de privacidad.
            <br />
            RCP-E para Enfermería® es responsable del uso y protección de sus
            datos personales, en este sentido y atendiendo las obligaciones
            legales establecidas en la Ley Federal de Protección de Datos
            Personales en Posesión de los Particulares, a través de este
            instrumento se informa a los titulares de los datos, la información
            que de ellos se recaba y los fines que se le darán a dicha
            información.
            <br />
            Informamos que RCP-E para Enfermería® tiene como domicilio comercial
            la siguiente dirección: Calle Salina Cruz 20, Roma Sur, Cuauhtémoc,
            06760 Ciudad de México.
            <br />
            FINALIDADES DEL TRATAMIENTO Únicamente solicitaremos aquellos datos
            personales que sean necesarios para la cotización, contratación,
            cobranza y uso de cursos, productos o servicios de su elección:
            Nombre completo, dirección, correo electrónico, números telefónicos
            personales, cursos de su interés, información laboral y profesional,
            información para facturación y cobranza tal como RFC, domicilio
            fiscal, considerado como personal, según la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares.
            <br />
            Dicho lo anterior, en RCP-E para Enfermería@ recabamos los datos
            personales para las siguientes finalidades:
            <br />
            Ofrecerle cotizaciones de los cursos, productos y servicios de su
            interés. Cumplimiento normativo, prevención de fraudes y
            colaboración con autoridades según corresponda. Cobrar, administrar,
            mantener, renovar o cancelar los cursos, productos y servicios
            contratados, así como generar estadística sobre los mismos. Envío de
            publicidad y encuestas.
            <br />
            Su información personal será utilizada para proveer los cursos,
            servicios y productos que ha solicitado, informarle sobre cambios en
            los mismos y evaluar la calidad del servicio que le brindamos.
            <br />
            En este sitio no recabamos información considerada como sensible de
            acuerdo a Ley Federal de Protección de Datos Personales en Posesión
            de los Particulares.
            <br />
            Por otra parte, informamos a usted, que sus datos personales no
            serán compartidos con ninguna autoridad, empresa, organización o
            persona distintas a nosotros y serán utilizados exclusivamente para
            los fines señalados.
            <br />
            MEDIOS Y PROCEDIMIENTOS PARA EJERCER DERECHOS ARCO (ACCESO,s
            RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN)
            <br />
            Tiene derecho a conocer los datos que tenemos de usted, para qué son
            utilizados y las condiciones de uso, es su derecho de solicitar la
            corrección de dichos datos en caso de que sea incorrectos, esten
            desatualizados o sea inexacta la información, tiene derecho a que su
            información se elimine de nuestra base de datos y así como también a
            oponerse al uso de sus datos personales para usos específicos. Dicho
            esto y conociendo sus derechos ARCO, usted podrá ejercer dichos
            derechos frente al Responsable.
            <br />
            Para ello el titular o representante legal deverá presentar la
            solicitud respectiva a través del siguiente correo electrónico:
            rcpparaenfermeria@gmail.com. Dicha solicitud deverá contener la
            siguiente información: Nombre, identificación oficial que acredite
            su persona, correo personal y motivos de
            acceso/rectificación/cancelaciñon/oposición. Cuando se quiera
            ejercer el derecho de rectificación, se tendrá que entregar la
            documentación y solicitud que acredite el cambio solicitado de
            acuerdo a los datos personales a rectificar.
            <br />
            La respuesta a dicho formato, se llevará a cabo por el Responsable
            después de 20 días hábiles contados a partir de la fecha en que fue
            recibido el mismo. El Responsable podrá ampliar por una sola vez
            éste plazo cuando el caso lo amerite, previa notificación de esto al
            Titular. Si su solicitud es procedente, podrá hacer efectivo su
            derecho ARCO dentro de los 15 días siguientes a la fecha de
            respuesta. Este plazo se podrá ampliar por una sola vez por causa
            justificada.
            <br />
            Asimismo, usted deberá considerar que para ciertos fines, la
            revocación, cancelación u oposición de su consentimiento implicará
            que no le podamos seguir prestando el curso, producto o servicio que
            nos solicitó, o la conclusión de su relación con nosotros. Esto
            deberá de revisarlo y tratarlo con el Responsable.
            <br />
            NOTIFICACIÓN DE CAMBIOS AL AVISO DE PRIVACIDAD El presente Aviso de
            Privacidad podrá ser modificado en cualquier momento para cumplir
            con actualizaciones legislativas, jurisprudenciales, políticas,
            internas, nuevos requisitos para la prestación de servicios de RCP-E
            para Enfermería® y para dar a conocer los cambios o actualizaciones
            antes mencionadas. Se publicaran a través de su página de internet
            www.rcpparaenfermeria.com, de en la cual se publicará de forma clara
            e inmediata cualquier actualización o cambios efectuados al mismo la
            página de internet. Asimismo, usted puede solicitar información
            sobre si el mismo ha sufrido algún cambio a través del siguiente
            correo electrónico: rcpparaenfermeria@gmail.com Última
            actualización: 01/02/2018{" "}
          </Body2>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
