import { extendTheme } from "@chakra-ui/react";
import { tabsTheme } from "./tabsTheme";

const theme = extendTheme({
  colors: {
    brand: {
      "100": "#001C54",
      "200": "#194B9B",
      "300": "#83B2FC",
      "400": "#CADEFC",
      "500": "#AFCDF9",
    },
    rcpBlue: {
      "100": "#262256",
    },
    crayola: "#71E2FF",
    kellyGreen: "#72C03D",
    sunglow: "#FFD341",
    lavander: { "100": "#BC91FF" },
    fireRed: { "100": "#DD6464" },
    gray: {
      "1": "#8A8A8A",
      "2": "#eee",
    },
  },
  components: {
    Button: {
      baseStyle: {
        padding: "16px 24px",
        borderRadius: "16px",
      },
    },
    Tabs: tabsTheme,
  },
  fonts: {
    heading:
      '"Roboto", Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif',
    body: '"Roboto", Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
});

export default theme;
