import Headroom from "react-headroom";

import Header from "@/components/Header";
import Footer from "./Footer";
import { Suspense } from "react";
import FullscreenLoader from "@/components/FullscreenLoader";
import { Outlet, ScrollRestoration } from "react-router-dom";

const MainLayout = () => {
  return (
    <>
      <Headroom style={{ zIndex: 9999 }}>
        <Header />
      </Headroom>
      <ScrollRestoration />
      <Suspense fallback={<FullscreenLoader />}>
        <Outlet />
      </Suspense>
      <Footer />
    </>
  );
};

export default MainLayout;
