import { Text, TextProps } from "@chakra-ui/react";

export interface H3Props extends TextProps {
  children: React.ReactNode;
}

export default function H3({ children, ...props }: H3Props) {
  return (
    <Text as="h3" fontSize="22px" fontWeight={900} color="blck" {...props}>
      {children}
    </Text>
  );
}
