import { createSlice } from "@reduxjs/toolkit";

export interface CartState {
  [key: string]: any;
}

const initialState: CartState = {};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const course = action.payload;
      return { ...state, [course.id]: course };
    },
    removeFromCart: (state, action) => {
      const id = action.payload;
      delete state[id];
    },
    clearCart: (state) => {
      return initialState;
    },
  },
});

export const actions = cartSlice.actions;

export default cartSlice.reducer;
