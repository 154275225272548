import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    color="#83B2FC"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.021 14.039a1 1 0 1 0 2 0h-2Zm2-6a1 1 0 1 0-2 0h2Zm-4 2a1 1 0 1 0 0 2v-2Zm6 2a1 1 0 1 0 0-2v2ZM1.717 17.524a1 1 0 1 0 .537 1.927l-.537-1.927Zm20.108 1.927a1 1 0 1 0 .53-1.928l-.53 1.928Zm-18.79-1.178a1 1 0 0 0 1.916-.574l-1.915.574ZM1 8l-.629-.777a1 1 0 0 0-.329 1.064L1 8Zm22 0 .957.291a1 1 0 0 0-.34-1.077L23 8Zm-3.998 9.695a1 1 0 1 0 1.913.582l-1.913-.582Zm-5.98-3.656v-3h-2v3h2Zm0-3v-3h-2v3h2Zm-4 1h3v-2h-3v2Zm3 0h3v-2h-3v2ZM2.253 19.45c1.651-.461 5.907-1.454 9.745-1.454v-2c-4.106 0-8.565 1.048-10.282 1.527l.537 1.927ZM12 17.997c3.84 0 8.15.993 9.826 1.454l.53-1.928c-1.739-.478-6.25-1.526-10.356-1.526v2Zm-7.048-.298L1.958 7.713l-1.916.574 2.994 9.986L4.95 17.7ZM1.63 8.777C2.759 7.864 5.755 6 12.001 6V4C5.314 4 1.869 6.01.37 7.223l1.258 1.554ZM12.001 6c6.238 0 9.201 1.86 10.381 2.787l1.236-1.573C22.09 6.014 18.693 4 12 4v2Zm10.042 1.709-3.04 9.986 1.912.582 3.042-9.986-1.914-.582Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;
