import { forwardRef, Text, TextProps } from "@chakra-ui/react";

export interface Body2Props extends TextProps {}

const Body2 = forwardRef<Body2Props, "p">(({ children, ...props }, ref) => {
  return (
    <Text
      as="p"
      fontSize="16px"
      fontWeight={400}
      color="brand.100"
      {...props}
      ref={ref}
    >
      {children}
    </Text>
  );
});

export default Body2;
