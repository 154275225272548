import { SVGProps } from "react";

type CartComponentProps = SVGProps<SVGSVGElement> & {
  showDot?: boolean;
};

const CartComponent = ({ showDot, ...props }: CartComponentProps) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#fff"
    {...props}
  >
    <g
      clipPath="url(#a)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M2.376 2h1.307c.246 0 .369 0 .468.045a.5.5 0 0 1 .213.185c.058.092.076.213.11.457L4.949 6m0 0L6 13.731c.133.982.2 1.472.435 1.841a2 2 0 0 0 .853.745c.397.183.892.183 1.883.183h8.558c.942 0 1.413 0 1.798-.17a2 2 0 0 0 .842-.696c.238-.346.327-.81.503-1.735l1.324-6.95c.062-.325.093-.488.048-.615a.5.5 0 0 0-.22-.266C21.907 6 21.741 6 21.41 6H4.948Zm5.428 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm8 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        stroke="auto"
      />
      {showDot && (
        <path
          d="M23.002 5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
          fill="#DD6464"
          stroke="#D12828"
        />
      )}
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.001)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CartComponent;
